.input_phone_container {
  width: 100% !important;
  display: flex;
  justify-content: space-between !important;
  font-family: inherit;
  font-size: 16px
}

.input_phone_text {
  width: 100% !important;
  border-radius: 0 !important;
  border-top-style: hidden !important;
  border-right-style: hidden !important;
  border-left-style: hidden !important;
  border-bottom-color: var(--black) !important;
  border-bottom-width: 2px !important;
  font-size: 16px !important;
}

.input_phone_btn {
  border-radius: 0 !important;
  border-top-style: hidden !important;
  border-right-style: hidden !important;
  border-left-style: hidden !important;
  border-bottom-color: var(--charcoal) !important;
  border-bottom-width: 2px !important;
}
