@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.quill {
    width: 100%;
    height: 12em !important;
    padding-bottom: 2em;
    margin-top: 0.5em;
}

.ql-toolbar {
    border-top: 2px solid black !important;
    border-left: 2px solid black !important;
    border-right: 2px solid black !important;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.ql-snow {
    border-bottom: 2px solid black !important;
}

.ql-container {
    border-bottom: 2px solid black !important;
    border-left: 2px solid black !important;
    border-right: 2px solid black !important;
    background-color: white;
    color: black;
    font-size: 1em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: 'Switzer', 'sans-serif' !important;
}

