@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/switzer");

@layer components {
  .black-button {
    @apply bg-black flex justify-center items-center text-white rounded-3xl h-10 px-9 hover:opacity-80 active:opacity-60 font-medium border-2 border-black font-Switzer cursor-pointer;
  }
  .medium-white-button {
    @apply bg-white flex justify-center items-center text-black rounded-3xl h-10 px-6 hover:opacity-80 active:opacity-60 font-medium border-2 border-black whitespace-nowrap font-Switzer cursor-pointer;
  }
  .medium-white-button-no-hover {
    @apply bg-white flex justify-center items-center text-black rounded-3xl h-10 px-6 active:opacity-60 font-medium border-2 border-black whitespace-nowrap font-Switzer cursor-pointer;
  }
  .medium-black-button {
    @apply bg-black flex justify-center items-center text-white rounded-3xl h-10 px-6 hover:opacity-80 active:opacity-60 font-medium whitespace-nowrap font-Switzer border-2 border-black cursor-pointer;
  }
  .medium-black-button-no-hover {
    @apply bg-black flex justify-center items-center text-white rounded-3xl h-10 px-6 font-medium whitespace-nowrap font-Switzer border-2 border-black;
  }
  .medium-green-button {
    @apply bg-IAGreen flex justify-center items-center text-black rounded-3xl h-10 px-6 hover:opacity-80 active:opacity-60 font-medium whitespace-nowrap font-Switzer border-2 border-IAGreen cursor-pointer;
  }
  .medium-white-button {
    @apply bg-white flex justify-center items-center text-black rounded-3xl h-10 px-6 hover:opacity-80 active:opacity-60 font-medium whitespace-nowrap font-Switzer border-2 border-black cursor-pointer;
  }
  .small-black-button {
    @apply bg-black flex justify-center items-center text-white rounded-2xl h-7 px-4 hover:opacity-80 active:opacity-60 whitespace-nowrap font-Switzer border-2 border-black cursor-pointer;
  }
  .small-yellow-button {
    @apply bg-IAYellow flex justify-center items-center text-black rounded-2xl h-7 px-4 hover:opacity-80 active:opacity-60 whitespace-nowrap font-Switzer border-2 border-IAYellow cursor-pointer;
  }
  .small-green-button {
    @apply bg-IAGreen flex justify-center items-center text-black rounded-2xl h-7 px-4 hover:opacity-80 active:opacity-60 whitespace-nowrap font-Switzer border-2 border-IAGreen cursor-pointer;
  }
  .small-red-button {
    @apply bg-IARed flex justify-center items-center text-black rounded-2xl h-7 px-4 hover:opacity-80 active:opacity-60 whitespace-nowrap font-Switzer border-2 border-IARed cursor-pointer;
  }
  .small-purple-button {
    @apply bg-IAPurple flex justify-center items-center text-black rounded-2xl h-7 px-4 hover:opacity-80 active:opacity-60 whitespace-nowrap font-Switzer border-2 border-IAPurple cursor-pointer;
  }
  .small-white-button {
    @apply bg-white flex justify-center items-center text-black rounded-2xl h-7 px-4 hover:opacity-80 active:opacity-60 border-2 border-black whitespace-nowrap font-Switzer cursor-pointer;
  }
  .extra-small-black-button-no-hover {
    @apply bg-black flex justify-center items-center text-white rounded-xl h-6 px-2 active:opacity-60 border-2 border-black whitespace-nowrap font-normal font-Switzer cursor-pointer;
  }
  .extra-small-white-button-no-hover {
    @apply bg-white flex justify-center items-center text-black rounded-xl h-6 px-2 active:opacity-60 border-2 border-black whitespace-nowrap font-normal font-Switzer cursor-pointer;
  }
  .extra-small-transparent-button-no-hover {
    @apply bg-transparent flex justify-center items-center text-black rounded-xl h-6 px-2 active:opacity-60 border-2 border-black whitespace-nowrap font-normal font-Switzer cursor-pointer;
  }
  .extra-small-white-border-button-no-hover {
    @apply bg-black flex justify-center items-center text-white rounded-xl h-6 px-2 active:opacity-60 border-2 border-white whitespace-nowrap font-normal font-Switzer cursor-pointer;
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e33b45;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
